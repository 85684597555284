<template>
  <svg class="dr-logo" xmlns="http://www.w3.org/2000/svg" width="158" height="38" viewBox="0 0 158 38" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.7375 0C41.6388 0 39.9375 1.70132 39.9375 3.8V34.2C39.9375 36.2987 41.6388 38 43.7375 38H74.1375C76.2362 38 77.9375 36.2987 77.9375 34.2V3.8C77.9375 1.70132 76.2362 0 74.1375 0H43.7375ZM58.9375 35.15C67.8569 35.15 75.0875 27.9194 75.0875 19C75.0875 10.0806 67.8569 2.85 58.9375 2.85C50.0181 2.85 42.7875 10.0806 42.7875 19C42.7875 27.9194 50.0181 35.15 58.9375 35.15Z" :fill="theme"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.83906 0C1.74038 0 0.0390625 1.70132 0.0390625 3.8V34.2C0.0390625 36.2987 1.74038 38 3.83906 38H34.2391C36.3377 38 38.0391 36.2987 38.0391 34.2V3.8C38.0391 1.70132 36.3377 0 34.2391 0H3.83906ZM35.2958 14.0778C35.3023 13.07 34.9081 12.1009 34.2001 11.3837L27.0186 4.10906C26.3106 3.39185 25.3466 2.98527 24.3388 2.97878L14.1168 2.91295C13.109 2.90646 12.1399 3.30058 11.4227 4.00861L4.14814 11.1901C3.43092 11.8981 3.02435 12.8621 3.01786 13.8699L2.95202 24.0919C2.94553 25.0997 3.33966 26.0688 4.04769 26.786L11.2292 34.0606C11.9372 34.7778 12.9012 35.1844 13.909 35.1909L24.1309 35.2567C25.1387 35.2632 26.1079 34.8691 26.8251 34.161L34.0997 26.9795C34.8169 26.2715 35.2234 25.3076 35.2299 24.2998L35.2958 14.0778Z" :fill="theme"/>
    <rect x="79.8398" width="38" height="38" rx="2" :fill="theme"/>
    <rect x="119.738" width="38" height="38" rx="2" :fill="theme"/>
    <path d="M89.3398 31.54V7.59998H97.5136C104.559 7.59998 109.381 12.388 109.381 19.57C109.381 26.239 104.901 31.54 97.5136 31.54H89.3398ZM93.7516 27.3676H97.2058C101.96 27.3676 104.593 24.5632 104.593 19.57C104.593 14.611 101.96 11.8066 97.2058 11.8066H93.7516V27.3676Z" :fill="themeInvert"/>
    <path d="M142.918 22.2376L148.869 31.198V31.54H143.465L137.959 22.7848H133.924V31.54H129.238V7.59998H139.738C144.799 7.59998 147.74 11.02 147.74 15.0898C147.74 18.5782 145.859 21.1774 142.918 22.2376ZM133.924 11.8066V18.6124H139.498C141.892 18.6124 142.918 17.0392 142.918 15.124C142.918 13.3114 141.858 11.8066 139.498 11.8066H133.924Z" :fill="themeInvert"/>
  </svg>
</template>

<script setup>
import { computed } from 'vue';

// define props
const props = defineProps({
  theme: String,
  required: false,
  default: 'light'
})

const theme = computed(() => {
  if (props.theme === 'light') {
    return 'black'
  }
  return 'white'
})

const themeInvert = computed(() => {
  if (props.theme === 'light') {
    return 'white'
  }
  return 'black'
})
</script>